var content = require("!!../../node_modules/css-loader/dist/cjs.js??ref--1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--1-2!../../node_modules/less-loader/dist/cjs.js??ref--1-3!./Index.less?vue&type=style&index=0&id=82b7485c&lang=less&scoped=true&");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
