import { render, staticRenderFns } from "./Index.html?vue&type=template&id=82b7485c&scoped=true&"
import script from "./Index.js?vue&type=script&lang=js&"
export * from "./Index.js?vue&type=script&lang=js&"
import style0 from "./Index.less?vue&type=style&index=0&id=82b7485c&lang=less&scoped=true&"
import style1 from "./IndexGlobal.less?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82b7485c",
  null
  
)

/* custom blocks */
import block0 from "./Index.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FIndex.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('82b7485c')) {
      api.createRecord('82b7485c', component.options)
    } else {
      api.reload('82b7485c', component.options)
    }
    module.hot.accept("./Index.html?vue&type=template&id=82b7485c&scoped=true&", function () {
      api.rerender('82b7485c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Index.vue"
export default component.exports