let i18nGlobal = {
  "en": {
    "Title": "Example Title",
    "Groups": "Group | Groups",
    "Readers": "Reader | Readers",
    "in {0} day": "in {0} day | in {0} days",
    "in {0} month": "in {0} month | in {0} months",
    "in {0} year": "in {0} year | in {0} years",
    "{0} word": "{0} word | {0} words",
    "You still need to write {0} words more.": "You still need to write {0} word more. | You still need to write {0} words more.",
    "You still need to delete {0} words more.": "You still need to delete {0} word more. | You still need to delete {0} words more.",
    "You still need to write {0} words more": "You still need to write {0} word more | You still need to write {0} words more",
    "You still need to delete {0} words more": "You still need to delete {0} word more | You still need to delete {0} words more",
    "You still need to write {0} keywords more": "You still need to write {0} keyword more | You still need to write {0} keywords more",
    "Remaining Time: {0}": "Remaining Time: {0}",
    "{0} sec": "{0} sec",
    "{0} Annotations": "{0} Annotation | {0} Annotations",
    "Total {0} Annotations": "Total {0} Annotation | Total {0} Annotations",
    '"{0}"': '"{0}"',
    "READING_PROGRESS.not-yet-started": "Not yet started",
    "READING_PROGRESS.PreImaginary": "Pre-Imaginary",
    "READING_PROGRESS.PreImaginaryKeyword": "Pre-Imaginary",
    "READING_PROGRESS.IndividualReading": "Focus Reading",
    "READING_PROGRESS.CollaborativeReading": "Assisting Reading",
    "READING_PROGRESS.PostRecall": "Post Recall",
    "READING_PROGRESS.PostRecallKeyword": "Post Recall",
    "READING_PROGRESS.FreeReading": "Free Reading",
    "ANNOTATION_TYPE.MainIdea": "Main Idea",
    "ANNOTATION_TYPE.ConfusedClarified": "Confused",
    "ANNOTATION_TYPE.Confused": "Confused",
    "ANNOTATION_TYPE.Clarified": "Clearified",
    "ANNOTATION_TYPE.SectionMainIdea": "Section Main Idea",
    "ANNOTATION_TYPE.ArticleMainIdea": "Article Main Idea",
    '{0}0K': '{0}0K',
    '{0}K': '{0}K',
    '.{0}K': '.{0}K',
    '{0} users': '{0} user | {0} users'
  },
  "zh-TW": {
    "LOGIN": "登入",
    "LOGOUT": "登出",
    "EXIT": "離開",
    "NEXT": "下一步",
    "Title": "標題",
    "OK": "確認",
    "UPLOAD": "上傳",
    "SUBMIT": "送出",
    "ADD": "新增",
    "EDIT": "編輯",
    "COMMENT": "留言",
    "DELETE": "刪除",
    "CANCEL": "取消",
    "CLOSE": "關閉",
    "SAVE": "儲存",
    "SELECT": "選擇",
    "Example": "範例",
    "DATABASE": "資料庫",
    "RESET": "重設",
    "PUBLIC": "公開",
    "SEARCH": "搜尋",
    "Search...": "搜尋...",
    "Path": "路徑",
    "Config": "設定",
    "Group": "分組",
    "Groups": "組",
    "Readers": "讀者",
    "Dashboard": "儀表板",
    "Not yet started": "尚未開始",
    "READING_PROGRESS.not-yet-started": "尚未開始",
    "READING_PROGRESS.PreImaginary": "閱讀前的預測",
    "READING_PROGRESS.PreImaginaryKeyword": "閱讀前的預測",
    "READING_PROGRESS.IndividualReading": "專注閱讀",
    "READING_PROGRESS.CollaborativeReading": "協助閱讀",
    "READING_PROGRESS.PostRecall": "閱讀後的回想",
    "READING_PROGRESS.PostRecallKeyword": "閱讀後的回想",
    "READING_PROGRESS.FreeReading": "自由閱讀",
    "READING_PROGRESS.finish": "已經完成",
    "in {0} day": "在{0}天內",
    "in {0} month": "在{0}月內",
    "in {0} year": "在{0}年內",
    "{0} word": "{0}字",
    "You still need to write {0} words more.": "您還需要寫{0}字。",
    "You still need to delete {0} words more.": "您還需要刪除{0}字。",
    "You still need to write {0} words more": "您還需要寫{0}字",
    "You still need to delete {0} words more": "您還需要刪除{0}字",
    "You still need to write {0} keywords more": "您還需要寫{0}個關鍵詞",
    "Remaining Time: {0}": "剩餘時間：{0}",
    "ANNOTATION_TYPE.MainIdea": "關鍵詞",
    "ANNOTATION_TYPE.ConfusedClarified": "疑問",
    "ANNOTATION_TYPE.Confused": "疑問",
    "ANNOTATION_TYPE.Clarified": "已釐清",
    "ANNOTATION_TYPE.SectionMainIdea": "小節關鍵詞",
    "ANNOTATION_TYPE.ArticleMainIdea": "文章關鍵詞",
    "{0} sec": "{0}秒",
    "{0} Annotations": "{0}篇標註",
    "Total {0} Annotations": "總共{0}篇標註",
    "No More": "沒有更多資料了",
    "Are you sure to delete this annotation?": "您確定要刪除這個標註嗎？",
    '"{0}"': '「{0}」',
    '...': '…',
    ':': "：",
    'You': "您",
    'All Readers': "所有讀者",
    "You are still editing. Are you sure to discard changes?": "您依然在編輯中，您確定要離開嗎？",
    '{0}0K': '{0}萬',
    '{0}K': '{0}千',
    '.{0}K': '{0}百',
    'Assist': '協助',
    'Cancel Assist': '取消協助',
    'Focus Yourself': '只看您本人',
    'Cancel Focus': '取消專注閱讀',
    'Write something': '請寫筆記',
    
    'Assisting Reader Tool': '協助讀者工具',
    'Annotation Type Selector': '閱讀策略篩選器',
    
    
    '{0} users': '{0}人',
    
    'Export': '匯出',
    'Configuration': '設定',
    'Indicators': '指標',
    'Reload': '重新載入',
    'Copy': '複製'
  }
}

export default i18nGlobal