var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.db.config.inited,
          expression: "db.config.inited"
        }
      ],
      staticClass: "Index"
    },
    [
      _c("DigitTimer", { ref: "DigitTimer", attrs: { db: _vm.db } }),
      _vm._v(" "),
      _c("AudioControlPanel", {
        ref: "AudioControlPanel",
        attrs: { db: _vm.db }
      }),
      _vm._v(" "),
      _c("StopPanel", { ref: "StopPanel", attrs: { db: _vm.db } }),
      _vm._v(" "),
      _c("MessagePanel", { ref: "MessagePanel", attrs: { db: _vm.db } }),
      _vm._v(" "),
      _c("ProgressPanel", { ref: "ProgressPanel", attrs: { db: _vm.db } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }